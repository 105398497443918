<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Информация по посещаемости
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker
                id="example-datepicker"
                v-model="filter.date" class="mb-2"
                @context="changeDate"
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"></b-form-datepicker>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>Класс</th>
                <th>Общее количество детей</th>
                <th>Факт Напол</th>
                <th>Всего отстуствует</th>
                <th>По болезни</th>
                <th>По другим причинам</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in data.items" :key="k">
                <td>{{item.unitName}}</td>
                <td>{{item.allStudentsCount}}</td>
                <td>{{item.fullness}}</td>
                <td>{{item.totalAbsent}}</td>
                <td>{{item.absentDueToIllness}}</td>
                <td>{{item.absentOtherReason}}</td>
              </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Всего</td>
                  <td>{{data.allStudentsCount}}</td>
                  <td>{{data.fullness}}</td>
                  <td>{{data.totalAbsent}}</td>
                  <td>{{data.absentDueToIllness}}</td>
                  <td>{{data.absentOtherReason}}</td>
                </tr>
              </tfoot>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";

export default {
  name: "EducationDepartmentPreschoolAbsenceLogReport",
  components: {
    KTPortlet
  },
  data() {
    return {
      regions:[],
      filter:{
        date: new Date(),
        regionId: 0
      },
      data:{
        items:[]
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    changeDate() {
      this.reloadData();
    },
    reloadData(){
      let $this = this;
      $this.loading = true
      ApiService.postSecured("educationDepartment/preschoolAbsenceLogReport", this.filter).then(({data}) => {
        console.log(data);
        $this.data = data;
      }).catch(() => {
        
      }).finally(function (){
        $this.loading = false;
      });
    },
    showMainDepartment(){
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    }
  }
};
</script>
